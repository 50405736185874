import styled from "styled-components"

export const Container = styled.div`
  background-color: #fff;
  padding: 25px;
`

export const Grid = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 35px;
  justify-items: center;

  & > div {
    width: 100%;

    & > h3 {
      font-size: 1.15rem;
      margin-bottom: 5px;
    }

    & > table tr.hidden {
      display: none;
    }

    & > table th {
      width: 25%;
    }
  }
`
