import React from "react"
import { Router } from "@reach/router"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Generate from "../components/donation-report/Generate"

const Empty = () => <div></div>

const Donation = () => {
  return (
    <Layout>
      <Helmet />
      <Router>
        <Generate path="/donation-report/:id" />
        <Empty exact path="/donation-report" />
      </Router>
    </Layout>
  )
}

export default Donation
