import React, { useState, useEffect, useRef } from "react"
import { v4 as uuidv4 } from "uuid"

import { API_URL } from "../../../utils/helpers"
import { getUser } from "../../../utils/auth"

import Alert from "../../Alert/index"

import Nav from "../../Nav/index"

import Input from "../../form/Input"
import Msg from "../../form/Msg"
import Submit from "../../form/Submit"

import { Container, Grid } from "./styled"

const Generate = ({ id }) => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const initRef = useRef(false)

  useEffect(() => {
    if (initRef.current) return
    initRef.current = true
    if (getUser()) {
      init(id, getUser().token)
    }
  })

  const init = async (id, token) => {
    const url = new URL(`${API_URL}/`)
    const params = { 
      admin: "1",
      f: "adminDonationReportInit",
      id, token,
      x: uuidv4() 
    }
    url.search = new URLSearchParams(params)

    try {
      const response = await fetch(url, {
        method: "GET",
        cache: "no-store",
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setNames(json)
        setAlert({})
      } else {
        setAlert({
          type: "error",
          text: "An error has occurred.",
        })
      }
    } catch (error) {
      setAlert({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/?f=adminDonationReport&admin=1`)
    let formData = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append("token", getUser().token || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setMsg({})
        setMsg({
          type: "success",
          text: json.text,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Container>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <useRef>
          <Nav
            sections={[
              { name: "Admin Home", to: "/" },
              { name: "", to: "" },
              { name: "Donation Reports", to: "/donation-reports" },
              { name: "", to: "" },
              {
                name: id === "all" ? `All Charities` : `Charity ${id}`,
                to: "",
              },
            ]}
            title="Generate / Email"
          />

          <p className="no-top-margin">
            Use this page to generate and email <strong>{names.email}</strong> a
            .csv export of submitted donations for {names.name}.
          </p>

          <Grid>
            <div>
              <h3>Charity’s Donation Details</h3>

              <table>
                <tbody>
                  <tr>
                    <th className="right">Name:</th>
                    <td>{names.name}</td>
                  </tr>
                  <tr className={names.code === "all" ? "hidden" : ""}>
                    <th className="right">Code:</th>
                    <td>
                      <a
                        href={`https://theclassiccares.org/birdies/report/?code=${names.code}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {names.code}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="right">Number of Donations:</th>
                    <td>{names.number}</td>
                  </tr>
                  <tr>
                    <th className="right">Total:</th>
                    <td>{names.total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>

          <useRef>
            {names.number === "0" ? (
              <Alert
                data={{
                  type: "error",
                  text: "No donations report available.",
                }}
              />
            ) : (
              <form onSubmit={e => handleSubmit(e)}>
                <div className="two">
                  <div>
                    <Input
                      type="text"
                      label="Start Date"
                      req={true}
                      name="start"
                      value={names.start}
                      update={handleUpdate}
                      errors={errors}
                    />
                  </div>
                  <div>
                    <Input
                      type="text"
                      label="End Date"
                      req={true}
                      name="end"
                      value={names.end}
                      update={handleUpdate}
                      errors={errors}
                    />
                  </div>
                </div>

                {msg.type && <Msg data={msg} />}

                {msg.type !== "working" && (
                  <Submit label="Generate & Email Report" />
                )}
              </form>
            )}
          </useRef>
        </useRef>
      )}
    </Container>
  )
}

export default Generate
